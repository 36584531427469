@font-face {
    src: url("../fonts/cocogoose-pro.regular.ttf");
    font-family: Cocogoose-Pro;
}

@font-face {
    src: url("../fonts/cocogoose-pro.semilight.ttf");
    font-family: Cocogoose-Pro-Semilight;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Book.otf");
    font-family: Avenir-Book;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Medium.otf");
    font-family: Avenir-Medium;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Roman.otf");
    font-family: Avenir-Roman;
}

body {
    background-color: #FAFAFA !important;
    font-family: "Avenir-Book", sans-serif;
}

/* Button */
.ui.button {
    border-radius: .15rem !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 900 !important;
    padding: 12px !important;
    min-width: 133px !important;
    letter-spacing: .12em !important;
}

@media (min-width: 992px) {
    .ui.icon.left.labeled {
        margin: 0 0 0 10px;
    }
}

.ui.button.primary {
    background: #078ec9 !important;
    border: 1px solid transparent;
}

.ui.button.primary:hover {
    border: 1px solid #078ec9;
    background: transparent !important;
    color: #078ec9;
}

.ui.button.positive {
    background: #9ce55d !important;
    border: 1px solid transparent;
}

.ui.button.positive:hover {
    background: transparent !important;
    color: #9ce55d;
    border: 1px solid #9ce55d;
}


/* Checkbox */
.checkbox {
    margin-bottom: 10px;
}

.checkbox input[type="checkbox"] { display: none; }

.checkbox input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px 'Open Sans', Arial, sans-serif;
    color: #000;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox input[type="checkbox"] + label:last-child { margin-bottom: 0; }

.checkbox input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #078ec9;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .6;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

.checkbox input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sidenav .collapsible .nav-list {
    margin-top: 15px;
}

.sidenav .collapsible .checkbox label:after {
    display: none !important;
}

/* Modal */
.modal {
    padding-bottom: 0 !important;
}

.modal .negative {
    background-color: transparent !important;
    border: 1px solid #078ec9 !important;
    color: #078ec9 !important;
}

.modal .negative:hover {
    background-color: #078ec9 !important;
    color: #fff !important;
}

.regular-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    transform: translateY(-120px);
    text-align: center !important;
    overflow-y: auto !important;
    height: 280px;
}

.regular-modal.step-2 {
    transform: translateY(-350px);
    height: 730px;
}

.regular-modal.unsub-modal {
    height: 245px;
    text-align: left !important;
}

.mini-modal, .p2p-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: auto !important;
    bottom: auto !important;
}

.mini-modal {
    transform: translateY(-100px) !important; 
}

.p2p-modal {
    transform: translateY(-250px) !important;
}

.p2p-modal.ui.modal>.content {
    padding: 10px 1.5rem !important;
}

.p2p-modal.ui.modal>.content p, .p2p-modal.ui.modal>.content h4 {
    padding: 0 !important;
    margin: 0 !important;
}

.p2p-modal.ui.modal>.content table {
    margin: 10px 0 !important;
}

.large-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 730px;
    transform: translateY(-350px);
    overflow: auto !important;
}

.large-modal.msisdn {
    height: 720px;
}


.ui.modal .header {
    padding-bottom: 30px !important;
    padding-top: 0px !important;
    margin-top: -40px !important;
    font-size: 18px !important;
}

.ui.modal .actions {
    background-color: transparent !important;
}

.modal .content p {
    margin-top: 0 !important;
}

.modal input, .modal .ui.form .field>.selection.dropdown {
    height: 35px !important;
    font-size: 15px !important;
    border-radius: 0 !important;
}

.rdt input {
    font-size: 15px !important;
    border-radius: 0 !important;
}

.modal input:not([readonly]) {
    margin-bottom: 15px !important;
}

input:read-only {
    box-shadow: 0 0 0 100px ivory inset!important;
    border-color: #e5dfa1!important;
    border: 1px solid rgba(34,36,38,.15);
}

.modal .ui.form .field {
    position: relative;
}

.modal .ui.form .field>.selection.dropdown {
    min-height: 35px !important;
    padding-top: 10px !important;
}

.modal .ui.form .fields {
    margin: 13px 0 !important;
}

.modal .ui.form .field>.selection.dropdown {
    margin: 0 0 13px 0;
}

.modal .ui.form .fields:nth-child(1), .modal .ui.form .field:nth-child(2) {
    margin: 0 !important;
}

.modal label {
    font-size: 13px !important;
}

.large-modal>.content {
    padding: 13px 1.5rem 0 1.5rem !important
}

.regular-modal>.content {
    padding: 13px 1.5rem !important;
}

.ui.selection.dropdown .menu>.item {
    padding: 10px 15px !important;
}

.ui.selection .item .text {
    font-size: 15px !important;
}

header, .header {
    height: 0 !important;
}

.ui.steps {
    margin: 0 !important;
}

.ui.steps .step {
    font-size: 14px !important;
    padding: 10px 30px;
}

.ui.ordered.steps .step:before {
    font-size: 1.2rem;
}
.iban-error {
    margin-top: 0 !important;
}

.formik-error-msg {
    color: #078ec9;
    font-size: 13px;
    position: absolute;
    left: 10px;
    bottom: -8px;
}

.login-formik-error-msg {
    color: #078ec9;
    font-size: 13px;
}

.unsub-modal select {
    min-height: 40px !important;
    font-size: 14px !important;
    padding-top: 0 !important;
}

/* Navbar */
header.header-6, .header.header-6 {
    background-color: #078ec9 !important;
}

header .header-nav .nav-link:first-of-type::before, header .header-nav .nav-link:last-of-type::after, .header .header-nav .nav-link:first-of-type::before, .header .header-nav .nav-link:last-of-type::after {
    display: none !important;
}

header .settings .nav-link, header .header-nav .nav-link, header .header-actions .nav-link, .header .settings .nav-link, .header .header-nav .nav-link, .header .header-actions .nav-link {
    color: #ffffff !important;
    opacity: 1 !important;
    font-family: "Avenir-Medium", sans-serif;
    font-size: 16px;
}

.ui.dropdown .menu {
    border-radius: 0 !important;
}

.ui.dropdown .menu>.item {
    font-size: .88rem;
}

.ui.dropdown .menu .item span {
    color: #000000 !important;
    text-decoration: none !important;
}

.ui.dropdown .menu .item {
    padding: 8px 20px !important;
}

.ui.dropdown .menu .item:hover {
    background-color: #078ec9;
}
.ui.dropdown .menu .item:hover span, .ui.dropdown .menu .item:hover {
    color: #ffffff !important;
}


.ui.dropdown .menu a {
    text-decoration: none !important;
}

.user-dropdown {
    padding: 0 0 20px 0 !important;
    width: 300px !important;
    text-align: center !important;
}

.user-dropdown div {
    background-color: #078ec9;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: left !important;
}

.nav-icon i {
    font-size: 1.2rem !important;
    margin-left: -5px;
}

/* Subnav */
.react-tabs-container {
    border: none !important;
    background-color: #FAFAFA !important;
    height: 45px !important;
}

.react-tabs-tab {
    font-size: 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #EEEEEE !important;
    border: 1px solid #D7D7D7 !important;
    border-bottom: 1px solid #D7D7D7 !important;
    border-left: none !important;
    color: #000000 !important;
}

.react-tabs-active {
    border-bottom: none !important;
    background-color: #FAFAFA !important;
    color: #078ec9 !important;
}

.react-tabs-tab-close {
    border: none !important;
    visibility: visible !important;
    line-height: 0 !important;
    background: none !important;
    cursor: pointer !important;
}

.react-tabs-child {
    padding: 0 !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    border-bottom: 1px solid #D7D7D7 !important;
}

.content-area {
    min-height: 85vh;
}

.sidenav {
    min-height: 100vh !important;
}

.sidenav h3 {
    font-family: "Cocogoose-Pro-Semilight", sans-serif;
    font-size: 18px;
}

.reports-container .content-area {
    padding-top: 1.2rem !important;
}

.content-area h2 {
    font-family: "Cocogoose-Pro-Semilight", sans-serif;
    font-size: 25px;
}

/* Table */
.ui.pagination.menu .active.item {
    background-color: #078ec9 !important;
    color: #fff !important;
}

.ui.table tfoot tr:first-child>th:only-child {
    text-align: center;
}

.ui.table .pressed {
    background-color: #078ec9;
    color: #ffffff;
}

.splitter-layout {
    max-width: 78vw !important;
    overflow-x: hidden !important;
}

.primary-pane {
    padding: 1.2rem 0 !important;
}

.splitter-layout .layout-pane.layout-pane-primary {
    padding-right: 20px !important;
}

.splitter-layout > .layout-splitter {
    display: none !important;
}

.content-container input {
    height: 50px;
}

/* Header Content */
.buttons-group-col, .buttons-group-col-2 {
    text-align: right;
}

@media (max-width: 992px) {
    .buttons-group-col, .buttons-group-col-2 {
        text-align: left;
        margin-top: -30px;
    }
}

/* Spinner */
.spinner {
    margin: 20px 0;
}

.spinner-container {
    text-align: center;
}

@media (max-height: 830px) {
    .large-modal, .regular-modal.step-2 {
        height: 90%;
        transform: translateY(-5%);
    }
}

@media (max-height: 786px) {
    .large-modal, .regular-modal.step-2 {
        height: 95%;
        transform: translateY(-7%);
    }
}

@media (max-height: 666px) {
    .large-modal, .regular-modal.step-2  {
        height: 95%;
        transform: translateY(-9%);
    }
}

@media (max-width: 576px) {
    .large-modal {
        transform: translateY(-80%);
    }
    .large-modal .header {
        margin-top: 10px !important;
    }
}

.content-area thead th, .content-area tbody td {
    font-size: 12px !important;
}

.reports-container .ui.table tfoot tr:first-child>th:only-child {
    text-align: left;
}

.table-overflow {
    overflow: auto;
    width: 100%;
    margin-top: 20px;
}

.ui.modal .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #000 !important;
}